import React from 'react'
import Img from 'gatsby-image'
import Button from './shortcodes/Button'
import HeroStyles from '../styles/Hero.module.sass'

/**
 * Display a custom hero image
 *
 * @param {string}  [aboveTitle]
 * @param {object}  [image]
 * @param {string}  [imageAlt]
 * @param {string}  title
 * @param {string}  [titleContinuation]
 * @param {string}  [subtitle]
 * @param {boolean} [showCTA=true]
 * @param {string}  [CTAText]
 * @param {string}  [CTAUrl]
 * @param {string}  [CTAText2]
 * @param {string}  [CTAUrl2]
 * @param {boolean} [showCTA2]
 */
const Hero = (props) => {
  console.log(props)

  return (
    <section
      className={`hero ${HeroStyles.hero} ${props.image ? 'with-image' : ''}`}
    >
      <div className={`container ${HeroStyles.container || ''}`}>
        {props.image ? (
          props.image.childImageSharp ? (
            <div className={`hero-image ${HeroStyles.image || ''}`}>
              <Img
                fluid={props.image.childImageSharp.fluid}
                alt={props.imageAlt || ''}
                title={props.imageAlt || ''}
              />
            </div>
          ) : (
            <div className={`hero-image ${HeroStyles.image || ''}`}>
              <img
                src={props.image}
                alt={props.imageAlt || ''}
                title={props.imageAlt || ''}
              />
            </div>
          )
        ) : null}
        <div className={`hero-body ${HeroStyles.heroBody || ''}`}>
          <div className={`container ${HeroStyles.container || ''}`}>
            <div className={HeroStyles.titleHolder}>
              {props.aboveTitle 
                && <span className={HeroStyles.aboveTitle || ''}>{props.aboveTitle}</span> 
              }
              <h1
                className={`title is-1 ${HeroStyles.title || ''}`}
                dangerouslySetInnerHTML={{ __html: props.title }}
              />
              {props.titleContinuation 
                && <span 
                  className={`title is-1 ${HeroStyles.titleContinuation || ''}`}
                  dangerouslySetInnerHTML={{ __html: ' ' + props.titleContinuation }}
                /> 
              }
            </div>
            
            {props.subtitle && (
              <p
                className={`subtitle ${HeroStyles.subtitle || ''}`}
                dangerouslySetInnerHTML={{ __html: props.subtitle }}
              />
            )}

            {props.showCTA !== false && props.showCTA !== 'false' && (
              <>
                <Button
                  url={props.CTAUrl || '/contact/'}
                  text={props.CTAText || 'Get a Quote'}
                  type={props.image && 'is-primary'}
                />
              </>
            )}

            {props.showCTA !== false && props.showCTA !== 'false' && props.showCTA2 !== false && props.showCTA2 !== 'false' && (
              <>
                <Button
                  url={props.CTAUrl2 || '/contact/'}
                  text={props.CTAText2 || 'Get a Quote'}
                  type={`is-secondary ${props.image && 'is-outlined'}`}
                />
              </>
            )}

            {props.children}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
