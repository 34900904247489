import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { add } from 'cart-localstorage'
import Phone from './Phone'
import ProductsStyles from '../../styles/Products.module.sass'

/**
 * Display a section with Products
 *
 * @param {string}  [type]
 * @param {string}  [className]
 * @param {string}  [title]
 * @param {string}  [intro]
 * @param {boolean} [hideCta=false]
 */
const Products = (props) => {
  const data = useStaticQuery(posts)

  const maxQty = 500

  function addToOrderAction(id, title, price, quantity) {
    add({ id: id, name: title, price: price }, quantity)

    // Reload the page to update the floating cart
    // TODO: Research using Redux for this purpose in the future
    window.location.reload()
  }

  // Create product ID maps
  let qtyArray = {}

  data.allMdx.edges.map(edge => {
    let id = edge.node.frontmatter.productID

    if (!qtyArray[id]) {
      qtyArray[id] = 1
    }

    return false
  })

  const [qty, setQty] = useState(qtyArray)

  // Increase/Decrease quantity using buttons
  function changeQty(operation, id) {
    // Prevent decreasing to 0 or increasing above maxQty
    if (
      (qty[id] <= 1 && operation === 'decrease') ||
      (qty[id] >= maxQty && operation === 'increase')
    ) {
      return false
    }

    // Clone and change array of quantities
    let _qty = qty

    if (operation === 'decrease') {
      _qty[id] = ~~_qty[id] - 1
    } else {
      _qty[id] = ~~_qty[id] + 1
    }

    // Replace quantity state with new array
    setQty({ ..._qty })
  }

  // Increase/Decrease quantity using number input
  function inputQty(input, id) {
    let _qty = qty

    if (input === '' || (~~input > 0 && ~~input <= maxQty)) {
      _qty[id] = ~~input
    }
    else if (input < 1) {
      _qty[id] = 1
    }
    else if (input > maxQty) {
      _qty[id] = maxQty
    }

    setQty({ ..._qty })
  }

  return (
    <div
      className={`force-full-width ${props.className || ''} ${ProductsStyles.products || ''}`}
    >
      <div className={`container is-semi-narrow ${ProductsStyles.container || ''}`}>
        {props.title && <h2 className={`title is-2 ${ProductsStyles.title || ''}`}>{props.title}</h2>}
        {props.intro && <div className={`is-narrow ${ProductsStyles.intro || ''}`} dangerouslySetInnerHTML={{ __html: props.intro }}></div>}
        <div className="columns is-multiline is-centered is-mobile">
          {data.allMdx.edges.map(edge => {
            // Filter by type
            if (props.type && props.type !== edge.node.frontmatter.productType) {
              return false
            }
            else {
              // Show 2 decimal places if the price has any decimals, otherwise show the price as a whole number (50.00 becomes 50, 12.2 becomes 12.20)
              let price = edge.node.frontmatter.price

              if ( price.toString().includes('.') ) {
                price = price.toFixed(2)
              }

              return (
                <div
                  key={edge.node.id}
                  className={`column is-one-third-tablet is-half-mobile ${ProductsStyles.productContainer || ''}`}
                >
                  <div className={ProductsStyles.product || ''}>
                    <div className={ProductsStyles.header || ''}>
                      <Img
                        className={ProductsStyles.image}
                        fluid={edge.node.fields.image.childImageSharp.fluid}
                      />
                    </div>
                    <div className={ProductsStyles.vitals}>
                      <strong className={ProductsStyles.productTitle}>
                        {edge.node.frontmatter.title}
                      </strong>
                      <p>{edge.node.frontmatter.shortDescription}</p>
                      <span className={ProductsStyles.price}>
                        <i>&euro;</i>
                        <b>{price}</b>
                      </span>
                    </div>
                    <div className={ProductsStyles.addToOrder}>
                      <div className={ProductsStyles.quantityWidget}>
                        <input
                          type="text"
                          name="quantity"
                          value={qty[edge.node.frontmatter.productID]}
                          onChange={e => {
                            inputQty(
                              e.target.value,
                              edge.node.frontmatter.productID
                            )
                          }}
                        />
                        <span
                          className={`tag ${ProductsStyles.increase || ''}`}
                          onClick={() => {
                            changeQty('increase', edge.node.frontmatter.productID)
                          }}
                          onKeyDown={() => {
                            changeQty('increase', edge.node.frontmatter.productID)
                          }}
                          role="button"
                          tabIndex="0"
                        >
                          +
                        </span>
                        <span
                          className={`tag ${ProductsStyles.decrease || ''}`}
                          onClick={() => {
                            changeQty('decrease', edge.node.frontmatter.productID)
                          }}
                          onKeyDown={() => {
                            changeQty('decrease', edge.node.frontmatter.productID)
                          }}
                          role="button"
                          tabIndex="0"
                        >
                          –
                        </span>
                      </div>
                      <button
                        className={`button is-secondary ${ProductsStyles.addToOrderButton || ''}`}
                        onClick={() => {
                          addToOrderAction(
                            edge.node.frontmatter.productID,
                            edge.node.frontmatter.title +
                              ' - ' +
                              edge.node.frontmatter.shortDescription,
                            edge.node.frontmatter.price,
                            qty[edge.node.frontmatter.productID]
                          )
                        }}
                      >
                        Add to Order
                      </button>
                    </div>
                  </div>
                  { edge.node.frontmatter.schema ?
                    <Helmet>
                      <script type="application/ld+json">
                        {edge.node.frontmatter.schema}
                      </script>
                    </Helmet>
                    : null 
                  }
                </div>
              )
            }
          })}
        </div>
        {
          ( props.hideCta !== 'true' )
          && 
          <div className={ProductsStyles.cta || ''}>
            <b>Prefer to order by phone?</b>
            <Phone class="button is-white" />
          </div>
        }
      </div>
    </div>
  )
}

export default Products

export const posts = graphql`
  {
    allMdx(filter: {frontmatter: {templateKey: {eq: "product"}}}, sort: {fields: frontmatter___productID, order: ASC}) {
      edges {
        node {
          id
          fields {
            slug
            image {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          frontmatter {
            shortDescription
            title
            price
            productType
            productID
            schema
          }
        }
      }
    }
  }
`
