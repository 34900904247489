import React from 'react'

/**
 * Display the default map
 *
 * @param {string} className
 */
const Map = props => {
  return (
    <div className={`map ${props.className || ''}`}>
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2382.4257484598106!2d-6.355231783846361!3d53.3356345799768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48670cc2243b1f1b%3A0x95e1b7d995e89d9a!2sSlatWall.ie%20-%20SlatWall%20%26%20Accessories%20Shop!5e0!3m2!1sen!2sie!4v1632831844394!5m2!1sen!2sie"
        width="600"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  )
}

export default Map
