import { graphql, useStaticQuery } from 'gatsby'

const SiteMetadata = () => {
  const data = useStaticQuery(
    graphql`
      {
        site: site {
          siteMetadata {
            title
            phone
            globalSchema
          }
        }
      }
    `
  )

  return data.site.siteMetadata
}

export default SiteMetadata
