import React from 'react'
import { Link } from 'gatsby'
import Phone from './Phone'
import CTAStyles from '../../styles/CTA.module.sass'

/**
 * Display a call-to-action section
 *
 * @param {string}  [text]
 * @param {string}  [link]
 * @param {string}  [before]
 * @param {bool}    [hidePhone=false]
 * @param {string}  [class]
 */
const CTA = props => {
  return (
    <div className={`cta ${CTAStyles.cta} ${props.class || ''}`}>
      {props.before ? <em>{props.before}</em> : null}
      <Link
        to={props.link || '/contact/'}
        className={`button is-primary ${CTAStyles.button || ''}`}
      >
        {props.text || 'Get a Quote'}
      </Link>
      { props.hidePhone !== "true" ? <Phone class={`button is-secondary ${CTAStyles.phone || ''}`} /> : null }
    </div>
  )
}

export default CTA
