import React from 'react'
import SiteMetadata from '../SiteMetadata'
import ContactFormStyles from '../../styles/ContactForm.module.sass'

/**
 * Display a functional contact form
 *
 * @param {string}  className
 */
const ContactForm = props => {
  const { title } = SiteMetadata()

  return (
    <div
      className={`form-container ${ContactFormStyles.form || ''} ${props.className || ''}`}
    >
      <div>
        <span className={`title ${ContactFormStyles.title || ''}`}>
          Get a Quote
        </span>

        <form
          name="Slatwall Enquiry"
          method="post"
          action="/thanks/"
          data-netlify="true"
          data-netlify-honeypot="first-name"
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="Slatwall Enquiry" />
          <div hidden>
            <label>
              Trap for non-humans: <input name="first-name" />
            </label>
          </div>
          <div className="field">
            <div className="control">
              <label htmlFor="name">
                Your Name
              </label>
              <input
                className="input"
                type={'text'}
                name={'name'}
                id={'name'}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <label htmlFor="email">
                Your Email
              </label>
              <input
                className="input"
                type={'email'}
                name={'email'}
                id={'email'}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <label htmlFor="phone">
                Phone Number
              </label>
              <input
                className="input"
                type={'tel'}
                name={'phone'}
                id={'phone'}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <label htmlFor="message">
                Message
              </label>
              <textarea
                className="textarea"
                name={'message'}
                id={'message'}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <button
              className={`button is-secondary ${ContactFormStyles.submit || ''}`}
              type="submit"
            >
              Send Enquiry
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ContactForm
